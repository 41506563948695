import React, { useState } from "react"

// Custom styles & Bootstrap
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import styles from "./FaqAccordion.module.scss"

export const FaqAccordion = ({ items }) => {
  const [eventKey, setEventKey] = useState(0)

  return (
    <Accordion defaultActiveKey={0} className={styles.faqAccordion}>
      {items.map((item, i) => (
        <Card
          key={i}
          className={
            eventKey === i
              ? `${styles.card} ${styles.active}`
              : `${styles.card}`
          }
        >
          <Accordion.Toggle
            as={Card.Header}
            eventKey={i}
            onClick={() => setEventKey(i)}
            className={
              eventKey === i
                ? `${styles.toggle} ${styles.bordered}`
                : `${styles.toggle} ${styles.unBordered}`
            }
          >
            {item.title}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={i}>
            <Card.Body
              dangerouslySetInnerHTML={{ __html: item.body }}
              className={styles.body}
            />
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}
